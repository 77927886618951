import React from "react";
import renderHTML from 'react-render-html';
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import moment from "moment";

export default class JobPostingSchema extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      job: props.job,
      partner: props.partner,
      variation: props.variation
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _getContract(job){
    let contract = job.contract;
    let schemaContract = "";
    if(contract === "internship"){
      schemaContract = "INTERN";
    }else if(contract === "apprenticeship"){
      schemaContract = "OTHER";
    }else if(contract === "part-time"){
      schemaContract = "PART_TIME";
    }else if(contract === "full-time"){
      schemaContract = "FULL_TIME";
    }else if(contract === "flexible"){
      schemaContract = "OTHER";
    }else if(contract === "contract"){
      schemaContract = "CONTRACTOR";
    }else if(contract === "temporary"){
      schemaContract = "TEMPORARY";
    }else{
      schemaContract = "OTHER";
    }
    return schemaContract;
  }

  _getJobSchema(job){
    let description = job.description.replace(/(\r\n|\n|\r)/gm, "<br/>").replace(/(<([^>]+)>)/ig,"").replace(/\\([\s\S])|(")/g, "\\$1$2");
    let datePosted = moment(job.publised_at).format('YYYY-MM-DD');
    let companyPage = window.General.CompanyPage.replace("[company]", job.company.slug)
    let logo = job.company.image_logo ? job.company.image_logo : 'https://dy793rr2xtptx.cloudfront.net/images2/topic/new/jobbio-logo-1559215894139.gif'

    return {
      "@context" : "http://schema.org/",
      "@type" : "JobPosting",
      "title" : job.title,
      "description" : description,
      "identifier": {
        "@type": "PropertyValue",
        "name":job.company.name,
        "value": job.id
      },
      "datePosted" : datePosted,
      "employmentType" : this._getContract(job),
      "hiringOrganization" : {
        "@type" : "Organization",
        "name" : job.company.name,
        "sameAs" : companyPage,
        "logo" : logo
      },
      "jobLocation" : {
        "@type" : "Place",
        "address" : {
          "@type" : "PostalAddress",
          "streetAddress" : job.location.address,
          "addressLocality" : job.location.city,
          "addressRegion" : job.location.state,
          "postalCode" : job.location.post_code,
          "addressCountry": job.location.country_short
        }
      },
      "baseSalary": {
        "@type": "MonetaryAmount",
        "currency": job.currency,
        "value": {
          "@type": "QuantitativeValue",
          "minValue": job.salary_from,
          "maxValue": job.salary_to,
          "unitText": job.salary_type
        }
      }
    }
  }

  render() {
    let {
      job,
      partner
    } = this.state

    if(job.redirect?.includes("stepstone")){
      return null
    }

    return (
      <>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{__html: JSON.stringify(this._getJobSchema(job))}}
        />
      </>
    )
  }
}
